import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";

import PWAInstallButton from "./PWAInstallButton";
import User from "./User";
import ResizableTable from "./ResizableTable";
import { Squares2X2Icon, Bars4Icon } from "@heroicons/react/24/outline";
import { API_URL } from "../../constants";

export default function Dashboard() {
  const [users, setUsers] = useState([]);

  const [viewMode, setViewMode] = React.useState(
    localStorage.getItem("viewMode") || "grid",
  );

  useEffect(() => {
    async function fetchUsers() {
      var tempUsers = await fetch(`${API_URL}api/v1/users`);

      var tempUsers = await tempUsers.json();
      setUsers(tempUsers);
    }
    fetchUsers();
  }, []);

  function switchView() {
    // Set the view mode to the opposite of the current view mode and update the state also local storage
    setViewMode(viewMode === "list" ? "grid" : "list");
    localStorage.setItem("viewMode", viewMode === "list" ? "grid" : "list");
  }

  return (
    <>
      <Navbar />
      <div className="bg-white dark:bg-slate-800 divide-y dark:divide-slate-500">
        <div className="flex items-center justify-center flex-col sm:flex-row sm:justify-between m-2">
          <p class="dark:text-white">Users View</p>
          <div className="flex flex-row m-0 flex-wrap justify-between sm:justify-center px-2 sm:px-0 w-full sm:w-auto mt-2">
            <PWAInstallButton></PWAInstallButton>

            <button
              onClick={switchView}
              className="py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
            >
              {" "}
              {viewMode === "list" ? (
                <Squares2X2Icon className="h-5 w-5 group-hover:animate-spin" />
              ) : (
                <Bars4Icon className="h-5 w-5 group-hover:animate-spin" />
              )}
            </button>
          </div>
        </div>
        <div id="content">
          {users.length > 0 && (
            <>
              {viewMode === "list" ? (
                <ResizableTable className="w-full border-none text-left ml-2">
                  <thead>
                    <tr>
                      <th className="rounded hover:bg-indigo-100 dark:hover:bg-slate-500 border-r border-gray-300 py-1 px-2 dark:text-white">
                        Name
                      </th>
                      <th className="pl-1 rounded hover:bg-indigo-100 dark:hover:bg-slate-500 border-r border-gray-300 py-1 px-2 dark:text-white">
                        Files uploaded
                      </th>
                      <th className="pl-1 rounded hover:bg-indigo-100 dark:hover:bg-slate-500 border-r border-gray-300 py-1 px-2 dark:text-white">
                        Last uploaded
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr
                        key={user.uid}
                        id="userItem"
                        data-object-id={user.uid}
                        style={{ maxWidth: "250px" }}
                        className="hover:bg-indigo-100 dark:hover:bg-slate-500 rounded-lg"
                      >
                        <User user={user} viewMode={viewMode} />
                      </tr>
                    ))}
                  </tbody>
                </ResizableTable>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {users.map((user) => (
                    <div
                      key={user.uid}
                      id="userItem"
                      data-object-id={user.uid}
                      style={{ maxWidth: "250px" }}
                      className="p-2"
                    >
                      <User user={user} viewMode={viewMode} />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Rightclick navbar */}
    </>
  );
}
