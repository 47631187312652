/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  MagnifyingGlassIcon,
  Bars3Icon,
  QuestionMarkCircleIcon,
  CalendarIcon,
  SparklesIcon,
  UserGroupIcon,
  InformationCircleIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  BookOpenIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useHistory } from "react-router-dom";
import { BellIcon } from "@heroicons/react/24/outline";

import ClassnotesBuddyLogoWhiteText from "../../logos/classnotesbuddy-logo-white-text.svg";
import ClassnotesBuddyLogoBlackText from "../../logos/classnotesbuddy-logo-black-text.svg";

import ClassnotesBuddyLogo from "../../logos/classnotesbuddy-logo.svg";
import { Disclosure, Menu } from "@headlessui/react";
import NotificationPopup from "./NotificationPopup";

import { useAuth } from "../../contexts/AuthContext";

const solutions = [
  {
    name: "Spotlight",
    description: "See who is the number one uploader of the site.",
    to: "/spotlight",
    icon: SparklesIcon,
  },
  {
    name: "Users",
    description: "See all the users who are using this application.",
    to: "/users",
    icon: UserGroupIcon,
  },
  {
    name: "Search",
    description: "Search files and folders easily and open them.",
    to: "/search",
    icon: MagnifyingGlassIcon,
  },
];
const callsToAction = [
  {
    name: "Watch Demo Video",
    to: "https://clipchamp.com/watch/3hQJ8n7mb9C",
    icon: PlayIcon,
  },
  { name: "Contact Support", to: "#", icon: PhoneIcon },
];
const resources = [
  {
    name: "About",
    description: "Get to know about Classnotes Buddy.",
    to: "/about",
    icon: InformationCircleIcon,
  },
  {
    name: "Features",
    description:
      "Learn how to maximize our platform to get the most out of it.",
    to: "/features",
    icon: QuestionMarkCircleIcon,
  },
  {
    name: "Privacy Policy",
    description: "Understand how we take your privacy seriously.",
    to: "#",
    icon: ShieldCheckIcon,
  },
];
const recentPosts = [
  {
    id: 1,
    name: "I am Asia's youngest Microsoft Power Platform Certified",
    to: "https://blog.techwithanirudh.com/posts/i-am-asia-s-youngest-microsoft-power-platform-certified",
  },
  {
    id: 2,
    name: "I am the world's youngest Microsoft Azure AI Certified",
    to: "https://blog.techwithanirudh.com/posts/i-am-the-world-s-youngest-microsoft-azure-ai-certified",
  },
  {
    id: 3,
    name: "What programming language should you learn?",
    to: "https://blog.techwithanirudh.com/posts/what-programming-language-should-you-learn",
  },
];

const userNavigation = [
  { name: "Your Profile", to: "/profile" },
  { name: "Logout", logout: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    try {
      await logout();
      history.push("/login");
    } catch {
      alert("Failed to log out");
    }
  }

  return (
    <Popover className="relative bg-white dark:bg-slate-800 border-b-2 border-gray-100 dark:border-slate-500">
      <div className="mx-auto px-2 sm:px-4">
        <div className="flex items-center justify-between py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/dashboard">
              <span className="sr-only">Classnotes Buddy</span>
              <img
                className="block lg:hidden h-8 w-auto sm:h-10"
                src={ClassnotesBuddyLogo}
                alt="Classnotes Buddy Logo"
              />
              <img
                className="hidden lg:block h-8 w-auto sm:h-10 dark:lg:hidden"
                src={ClassnotesBuddyLogoBlackText}
                alt="Classnotes Buddy Black Logo"
              />
              <img
                className="hidden dark:lg:block h-8 w-auto sm:h-10"
                src={ClassnotesBuddyLogoWhiteText}
                alt="Classnotes Buddy White Logo"
              />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white dark:bg-slate-800 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open
                        ? "text-gray-900 dark:text-gray-500"
                        : "text-gray-500 dark:text-gray-100",
                      "group inline-flex items-center rounded-md bg-white dark:bg-slate-800 text-base font-medium hover:text-gray-900 dark:hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                    )}
                  >
                    <span>Solutions</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500",
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 z-[100]">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-white dark:bg-slate-800 px-5 py-6 sm:gap-8 sm:p-8">
                          {solutions.map((item) => (
                            <Link
                              key={item.name}
                              to={item.to}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50 dark:hover:bg-slate-900"
                            >
                              <item.icon
                                className="h-6 w-6 flex-shrink-0 text-indigo-600"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-gray-100">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                                  {item.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        <div className="space-y-6 bg-gray-50 dark:bg-slate-800 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {callsToAction.map((item) => (
                            <div
                              key={item.name}
                              className="flow-root dark:bg-slate-800"
                            >
                              <a
                                href={item.to}
                                target="_blank"
                                className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-slate-900"
                              >
                                <item.icon
                                  className="h-6 w-6 flex-shrink-0 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-3">{item.name}</span>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>

            <a
              href="https://classnotes-buddy-services.techwithanirudh.repl.co/blog"
              target="_blank"
              className="text-base font-medium text-gray-500 dark:text-gray-100 hover:text-gray-900 dark:hover:text-gray-500"
            >
              Blog
            </a>
            <a
              href="https://classnotes-buddy-services.techwithanirudh.repl.co/"
              target="_blank"
              className="text-base font-medium text-gray-500 dark:text-gray-100 hover:text-gray-900 dark:hover:text-gray-500"
            >
              Docs
            </a>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open
                        ? "text-gray-900 dark:text-gray-500"
                        : "text-gray-500 dark:text-gray-100",
                      "group inline-flex items-center rounded-md bg-white dark:bg-slate-800 text-base font-medium hover:text-gray-900 dark:hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                    )}
                  >
                    <span>More</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500",
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0  z-[100]">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-white dark:bg-slate-800 px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <Link
                              key={item.name}
                              to={item.to}
                              className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50 dark:hover:bg-slate-900"
                            >
                              <item.icon
                                className="h-6 w-6 flex-shrink-0 text-indigo-600"
                                aria-hidden="true"
                              />
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900 dark:text-gray-100">
                                  {item.name}
                                </p>
                                <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                                  {item.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        <div className="bg-gray-50 dark:bg-slate-800 px-5 py-5 sm:px-8 sm:py-8">
                          <div>
                            <h3 className="text-base font-medium text-gray-500 dark:text-gray-300">
                              Recent Posts
                            </h3>
                            <ul role="list" className="mt-4 space-y-4">
                              {recentPosts.map((post) => (
                                <li
                                  key={post.id}
                                  className="truncate text-base"
                                >
                                  <a
                                    href={post.to}
                                    target="_blank"
                                    className="font-medium text-gray-900 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-300"
                                  >
                                    {post.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                          <div className="mt-5 text-sm">
                            <a
                              href="https://blog.techwithanirudh.com/"
                              target="_blank"
                              className="font-medium text-indigo-600 hover:text-indigo-500"
                            >
                              View all posts
                              <span aria-hidden="true"> &rarr;</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <NotificationPopup />

            {/* Profile dropdown */}
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className=" dark:bg-slate-800 flex text-sm rounded-full text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-indigo-500">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src={currentUser.photoURL}
                    alt=""
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {/* Loop through user navigation an render */}
                  {userNavigation.map((item) => (
                    <>
                      {!item.logout ? (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={item.to}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700",
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ) : (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="#"
                              onClick={handleLogout}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700",
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                    </>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-[100]"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white dark:bg-slate-800 shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={ClassnotesBuddyLogo}
                    alt="Classnotes Buddy Logo"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white dark:bg-slate-800 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {solutions.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className="-m-3 flex items-center rounded-md p-3 hover:bg-gray-50 dark:hover:bg-slate-900"
                    >
                      <item.icon
                        className="h-6 w-6 flex-shrink-0 text-indigo-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-100">
                        {item.name}
                      </span>
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <a
                  href="https://classnotes-buddy-services.techwithanirudh.repl.co/blog"
                  target="_blank"
                  className="text-base font-medium text-gray-900 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-300"
                >
                  Blog
                </a>

                <a
                  href="https://classnotes-buddy-services.techwithanirudh.repl.co/"
                  target="_blank"
                  className="text-base font-medium text-gray-900 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-300"
                >
                  Docs
                </a>
                {resources.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className="text-base font-medium text-gray-900 dark:text-gray-100 hover:text-gray-700 dark:hover:text-gray-300"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
            <div className="space-y-6 py-6">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={currentUser.photoURL}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium leading-none dark:text-white">
                    {currentUser.displayName}
                  </div>
                  <div className="text-sm font-medium leading-none text-gray-400 dark:text-gray-100">
                    {currentUser.email}
                  </div>
                </div>
                <NotificationPopup></NotificationPopup>
              </div>
              <div className="mt-3 px-2 space-y-1">
                {userNavigation.map((item) => (
                  <>
                    {!item.logout ? (
                      <Link
                        key={item.name}
                        to={item.to}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-500 hover:bg-gray-50 dark:hover:bg-slate-900"
                      >
                        {item.name}
                      </Link>
                    ) : (
                      <Link
                        key={item.name}
                        to={"#"}
                        onClick={() => handleLogout()}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-500 hover:bg-gray-50 dark:hover:bg-slate-900"
                      >
                        {item.name}
                      </Link>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
