import { Popover, Transition } from "@headlessui/react";
import { BellIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import Notifications from "./Notifications";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function NotificationPopup() {
  return (
    <Popover className="relative ml-auto">
      {({ open }) => (
        <>
          <Popover.Button
            className={`dark:bg-slate-800 flex-shrink-0 p-1 rounded-full text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-indigo-500`}
          >
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-0 z-10 mt-3 w-screen max-w-xs max-h-sm -translate-x-[78%] transform px-4 sm:px-0 lg:max-w-sm bg-white dark:bg-slate-800">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-2 bg-white dark:bg-slate-800 flex flex-row items-center justify-between divide-y divide-slate-200">
                  <h1 className="text-xl font-semibold mb-2 dark:text-white">
                    Notifications
                  </h1>
                  <Link
                    className="inline-flex justify-center py-1 px-2 border-none shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    to="/notifications"
                  >
                    View All{" "}
                    <ArrowRightIcon
                      className="ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
                <Notifications notificationPopup={true} />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
