import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import { database } from "../../firebase";
import { Player } from "@lottiefiles/react-lottie-player";
import { API_URL } from "../../constants";

function Spotlight() {
  // Get all the files from the database
  const player = useRef(null);

  const [files, setFiles] = useState([]);
  const [mostActiveUser, setMostActiveUser] = useState({
    user: {
      uid: "",
      email: "",
      emailVerified: false,
      displayName: "",
      photoURL: "",
      disabled: false,
    },
    count: 0,
  });
  var userFilesList = [];

  useEffect(() => {
    // List all the files in the database
    const unsubscribe = database.files
      .orderBy("createdAt")
      .onSnapshot((snapshot) => {
        const documents = snapshot.docs.map(database.formatDoc);
        setFiles(documents);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      files.map((file) => {
        // Get the user's file count in userFilesList
        userFilesList.forEach((obj) => {
          if (obj.user.uid === file.userId) {
            obj.count++;
          }
        });

        // If the user is not in the userFilesList, add them to the list
        if (
          !userFilesList.some((obj) => obj.user && obj.user.uid === file.userId)
        ) {
          userFilesList.push({
            user: {
              uid: file.userId,
              email: "",
              emailVerified: false,
              displayName: "",
              photoURL: "",
              disabled: false,
            },
            count: 1,
          });
        }
      });
      // Sort the userFilesList by the count
      userFilesList.sort((a, b) => b.count - a.count);
      // Set the mostActiveUser to the first user in the list
      var tempMostActiveUser = userFilesList[0];
      // Get the user by uid by calling api
      const user = await fetch(
        `${API_URL}api/v1/users/${tempMostActiveUser.user.uid}`,
      );

      // Set the mostActiveUser to the user object
      tempMostActiveUser = {
        ...userFilesList[0],
        user: await user.json(),
      };
      console.log("User:", userFilesList[0]);
      console.trace("Fetching user");
      setMostActiveUser(tempMostActiveUser);
    }
    fetchData();
  }, [files]);

  useEffect(() => {
    const applause = document.getElementById("applause");
    const applauseTrigger = document.getElementById("applauseTrigger");

    applauseTrigger.addEventListener("click", () => {
      applause.classList.remove("hidden");
      setTimeout(() => {
        applause.classList.add("hidden");
      }, 5000);
    });
  }, []);

  return (
    <>
      <Navbar />
      <div
        className="w-full h-full fixed top-0 bottom-0 bg-white z-[9999] text-center flex items-center justify-center hidden"
        id="applause"
      >
        <div id="lottie">
          <Player
            ref={player} // set the ref to your class instance
            autoplay={true}
            loop={true}
            src="https://assets5.lottiefiles.com/private_files/lf30_wafptztg.json"
            style={{ height: "300px", width: "300px" }}
          ></Player>
        </div>
      </div>
      <div className="h-full m-auto my-4 max-w-xl">
        <div className="max-w-xl bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <div className="p-5">
            <div className="rounded-full p-3 text-white text-2xl font-bold bg-indigo-500">
              <b>Hall of fame</b>
            </div>

            <div className="sm:text-left text-center my-5">
              <div className="flex shrink-0 flex-col items-center">
                <div className="relative">
                  <div className="absolute inset-0">
                    <img
                      className="h-52 w-52 rounded-lg object-cover opacity-25"
                      src={mostActiveUser && mostActiveUser.user.photoURL}
                    />
                  </div>
                  <div className="relative z-10">
                    <img
                      className="animate-wiggle h-52 w-52 rounded-lg object-cover shadow-xl"
                      src={mostActiveUser && mostActiveUser.user.photoURL}
                    />
                  </div>
                </div>
              </div>
              <div className="bg-transparent mt-8">
                <h3 className="text-2xl text-center dark:text-white">
                  {mostActiveUser && mostActiveUser.user.displayName}
                </h3>
                <p className="dark:text-white">
                  {mostActiveUser && mostActiveUser.user.displayName} has
                  uploaded {mostActiveUser && mostActiveUser.count} files
                </p>
                <p
                  className="animate-pulse text-yellow-500 mt-2 text-center"
                  id="applauseTrigger"
                >
                  Give a round of applause for{" "}
                  {mostActiveUser && mostActiveUser.user.displayName}
                  👏!
                </p>
              </div>
            </div>
            <a
              href={`https://twitter.com/intent/tweet?text=${
                mostActiveUser && mostActiveUser.user.displayName
              } has uploaded ${
                mostActiveUser && mostActiveUser.count
              } files on Notes Buddy!&url=https://notes-buddy.techwithanirudh.repl.co/&hashtags=notesbuddy`}
              target="_blank"
              class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Tweet about {mostActiveUser && mostActiveUser.user.displayName}
              <svg
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default Spotlight;
