import React, { useEffect } from "react";
import { useFolder } from "../../hooks/useFolder";
import AddFolderButton from "./AddFolderButton";
import AddFileButton from "./AddFileButton";
import Folder from "./Folder";
import File from "./File";
import Navbar from "./Navbar";
import FolderBreadcrumbs from "./FolderBreadcrumbs";
import { useParams, useLocation } from "react-router-dom";
import { database } from "../../firebase";

import { useAuth } from "../../contexts/AuthContext";

import ViewSlideshowButton from "./ViewSlideshowButton";

import PWAInstallButton from "./PWAInstallButton";

import { Squares2X2Icon, Bars4Icon } from "@heroicons/react/24/outline";

import ContextMenu from "./ContextMenu";

import ResizableTable from "./ResizableTable";
import { API_URL } from "../../constants";

import DarkModeButton from "./DarkModeButton";
// import FileInfoPanel from "./FileInfoPanel";

import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { storage } from "../../firebase";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { v4 as uuidV4 } from "uuid";
import { ProgressBar, Toast } from "react-bootstrap";

function getParents(elem) {
  var parents = [];
  while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() != "body") {
    elem = elem.parentNode;
    parents.push(elem);
  }
  return parents;
}

export default function Dashboard() {
  const { folderId } = useParams();
  const { state = {} } = useLocation();
  const { currentUser } = useAuth();
  const { folder, childFolders, childFiles } = useFolder(
    folderId,
    state.folder,
  );
  const [filesList, setFilesList] = React.useState([]);
  const [viewMode, setViewMode] = React.useState(
    localStorage.getItem("viewMode") || "grid",
  );

  function showOptions(e, contextMenuItems) {
    // Custom context menu
    e.preventDefault();

    // Get the react element that was clicked
    const element = e.target;
    // Get the parents of the element
    const parents = getParents(element);
    // Get the react component that was clicked
    const component = parents.find((parent) => parent.id === "driveItem");
    if (!component) return;
    // Get the type of element that was clicked
    const type = component.getAttribute("data-object-type");
    // Get the id of the element that was clicked
    const id = component.getAttribute("data-object-id");

    // Get the firestore document of the element that was clicked
    if (type === "folder") {
      database.folders
        .doc(id)
        .get()
        .then((doc) => {
          const folder = doc.data();
          if (folder.userId !== currentUser.uid) {
            alert("You do not have permission to edit this folder");
            return;
          }
          // Rename the folder
          const renameFolder = () => {
            const newName = prompt("New name");
            if (newName) {
              database.folders.doc(id).update({
                name: newName,
              });
            }
            contextMenuItems.outerHTML = contextMenuItems.outerHTML;
          };
          // Delete the folder
          const deleteFolder = () => {
            if (
              Boolean(
                prompt(
                  "Are you sure you want to delete this folder? This action cannot be undone. Type true to confirm.",
                ),
              )
            ) {
              database.folders.doc(id).delete();
            }

            contextMenuItems.outerHTML = contextMenuItems.outerHTML;
          };
          // Move the folder
          const moveFolder = () => {
            const newParentId = prompt("New parent ID");
            if (newParentId) {
              database.folders.doc(id).update({
                parentId: newParentId,
              });
            }

            contextMenuItems.outerHTML = contextMenuItems.outerHTML;
          };
          // Context menu options
          contextMenuItems.children[0].addEventListener("click", () => {
            contextMenuItems.outerHTML = contextMenuItems.outerHTML;
            renameFolder();
          });
          contextMenuItems.children[1].addEventListener("click", () => {
            contextMenuItems.outerHTML = contextMenuItems.outerHTML;
            deleteFolder();
          });
          contextMenuItems.children[2].addEventListener("click", () => {
            contextMenuItems.outerHTML = contextMenuItems.outerHTML;
            moveFolder();
          });
        });
    } else if (type === "file") {
      database.files
        .doc(id)
        .get()
        .then((doc) => {
          const file = doc.data();
          if (file.userId !== currentUser.uid) {
            alert("You do not have permission to edit this file");
            return;
          }

          const filePath =
            folder === ROOT_FOLDER
              ? `${folder.path.join("/")}/${file.name}`
              : `${folder.path.join("/")}/${folder.name}/${file.name}`;

          const storageRef = storage.ref(
            `/files/${currentUser.uid}/${filePath}`,
          );

          // Rename the file
          const renameFile = () => {
            const newName = prompt("New name");
            if (newName) {
              storageRef.getDownloadURL().then((url) => {
                // Download the file from the old url
                fetch(url)
                  .then((res) => res.blob())
                  .then((blob) => {
                    // Upload the file to the new url
                    storage
                      .ref(`/files/${currentUser.uid}/${filePath}`)
                      .put(blob)
                      .then(() => {
                        // Delete the file from the old url
                        storage.refFromURL(url).delete();

                        // Change the url in the database
                        database.files.doc(id).update({
                          name: newName,
                          url: `https://firebasestorage.googleapis.com/v0/b/${
                            storage.bucket
                          }/o/${encodeURIComponent(
                            storageRef.fullPath,
                          )}?alt=media&token=${currentUser.uid}`,
                        });
                      });
                  });
              });
            }
          };
          // Delete the file
          const deleteFile = () => {
            if (
              Boolean(
                prompt(
                  "Are you sure you want to delete this folder? This action cannot be undone. Type true to confirm.",
                ),
              )
            ) {
              database.files.doc(id).delete();

              storageRef.getDownloadURL().then((url) => {
                storage.refFromURL(url).delete();
              });
            }
          };
          // Move the file
          const moveFile = () => {
            const newParentId = prompt("New parent ID");
            if (newParentId) {
              database.files.doc(id).update({
                parentId: newParentId,
              });
            }
          };
          // Context menu options
          contextMenuItems.children[0].addEventListener("click", () => {
            contextMenuItems.outerHTML = contextMenuItems.outerHTML;
            renameFile();
          });
          contextMenuItems.children[1].addEventListener("click", () => {
            contextMenuItems.outerHTML = contextMenuItems.outerHTML;
            deleteFile();
          });
          contextMenuItems.children[2].addEventListener("click", () => {
            contextMenuItems.outerHTML = contextMenuItems.outerHTML;
            moveFile();
          });
        });
    }
  }

  useEffect(() => {
    let filesListTemp = [];
    // Loop through child files and get all links
    childFiles.forEach((file) => {
      const fileId = file.id;
      const fileURL = file.url;
      const fileName = file.name;

      filesListTemp.push({
        id: fileId,
        url: fileURL,
        name: fileName,
      });
    });
    setFilesList(filesListTemp);
  }, [childFiles]);

  function switchView() {
    // Set the view mode to the opposite of the current view mode and update the state also local storage
    setViewMode(viewMode === "list" ? "grid" : "list");
    localStorage.setItem("viewMode", viewMode === "list" ? "grid" : "list");
  }

  return (
    <>
      <div id="defaultContextmenuArea" className="defaultContextmenuArea"></div>
      <Navbar />
      <div className="bg-white dark:bg-slate-800 divide-y dark:dark:divide-slate-500">
        <div className="flex items-center justify-center flex-col sm:flex-row sm:justify-between m-2">
          <FolderBreadcrumbs currentFolder={folder} />
          <div className="flex flex-row m-0 flex-wrap justify-between sm:justify-center px-2 sm:px-0 w-full sm:w-auto mt-2">
            <DarkModeButton />
            {/* <FileInfoPanel
							id={`QmzLj7E7kbfp97o5s1Gb`}
							type={"folder"}
						/> */}
            <PWAInstallButton></PWAInstallButton>
            <ViewSlideshowButton filesList={filesList} />
            <button
              onClick={switchView}
              className="py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
            >
              {" "}
              {viewMode === "list" ? (
                <Squares2X2Icon className="h-5 w-5 group-hover:animate-spin" />
              ) : (
                <Bars4Icon className="h-5 w-5 group-hover:animate-spin" />
              )}
            </button>
            <AddFileButton currentFolder={folder} />
            <AddFolderButton currentFolder={folder} />
          </div>
        </div>
        <div id="content" onContextMenu={showOptions}>
          {childFolders.length > 0 && (
            <>
              {viewMode === "list" ? (
                <ResizableTable className="w-full border-none text-left ml-2">
                  <thead>
                    <tr>
                      <th className="rounded hover:bg-indigo-100 dark:hover:bg-slate-500 border-r border-gray-300 py-1 px-2 dark:text-white">
                        Name
                      </th>
                      <th className="pl-1 rounded hover:bg-indigo-100 dark:hover:bg-slate-500 border-r border-gray-300 py-1 px-2 dark:text-white">
                        Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {childFolders.map((childFolder) => (
                      <tr
                        key={childFolder.id}
                        id="driveItem"
                        data-object-id={childFolder.id}
                        data-object-type="folder"
                        style={{ maxWidth: "250px" }}
                        className="hover:bg-indigo-100 dark:hover:bg-slate-500 rounded-lg"
                      >
                        <Folder
                          folder={childFolder}
                          viewMode={viewMode}
                          currentFolder={folder}
                        />
                      </tr>
                    ))}
                  </tbody>
                </ResizableTable>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {childFolders.map((childFolder) => (
                    <div
                      key={childFolder.id}
                      id="driveItem"
                      data-object-id={childFolder.id}
                      data-object-type="folder"
                      style={{ maxWidth: "250px" }}
                      className="p-2"
                    >
                      <Folder
                        folder={childFolder}
                        viewMode={viewMode}
                        currentFolder={folder}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
          {childFolders.length > 0 && childFiles.length > 0 && <hr />}
          {childFiles.length > 0 && (
            <>
              {viewMode === "list" ? (
                <ResizableTable className="w-full border-none text-left ml-2">
                  <thead>
                    <tr>
                      <th className="rounded hover:bg-indigo-100 dark:hover:bg-slate-500 border-r border-gray-300 py-1 px-2 dark:text-white">
                        Name
                      </th>
                      <th className="rounded hover:bg-indigo-100 dark:hover:bg-slate-500 border-r border-gray-300 py-1 px-2 dark:text-white">
                        Date modified
                      </th>
                      <th className="pl-1 rounded hover:bg-indigo-100 dark:hover:bg-slate-500 border-r border-gray-300 py-1 px-2 dark:text-white">
                        Type
                      </th>
                      <th className="pl-1 rounded hover:bg-indigo-100 dark:hover:bg-slate-500 border-r border-gray-300 py-1 px-2 dark:text-white">
                        Size
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {childFiles.map((childFile) => (
                      <tr
                        key={childFile.id}
                        id="driveItem"
                        data-object-id={childFile.id}
                        data-object-type="file"
                        style={{ maxWidth: "250px" }}
                        className="hover:bg-indigo-100 dark:hover:bg-slate-500 rounded-lg"
                      >
                        <File
                          file={childFile}
                          viewMode={viewMode}
                          currentFolder={folder}
                        />
                      </tr>
                    ))}
                  </tbody>
                </ResizableTable>
              ) : (
                <div className="flex flex-row flex-wrap">
                  {childFiles.map((childFile) => (
                    <div
                      key={childFile.id}
                      id="driveItem"
                      data-object-id={childFile.id}
                      data-object-type="file"
                      style={{ maxWidth: "250px" }}
                      className="p-2"
                    >
                      <File
                        file={childFile}
                        viewMode={viewMode}
                        currentFolder={folder}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          <ContextMenu
            targetId="content"
            options={["Rename", "Delete", "Move"]}
            classes={{
              listWrapper:
                "w-64 h-auto rounded-xl bg-white p-1 text-lg shadow-lg",
              listItem:
                "flex flex-row items-center justify-between rounded-lg px-2 hover:bg-gray-100",
            }}
            callback={showOptions}
          />
        </div>
      </div>
      {/* Rightclick navbar */}
    </>
  );
}
