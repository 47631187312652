/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Disclosure, Menu, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
  BellIcon,
} from "@heroicons/react/24/outline";
import NotificationPopup from "../../../core/NotificationPopup";
import DarkModeButton from "../../../core/DarkModeButton";

import { useAuth } from "../../../../contexts/AuthContext";
import ClassnotesBuddyLogo from "../../../../logos/classnotesbuddy-logo.svg";

import { Link, useHistory } from "react-router-dom";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
  { name: "Features", href: "/features" },
];

const userNavigation = [
  { name: "Your Profile", href: "/profile" },
  { name: "Logout", logout: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Hero() {
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  async function handleLogout() {
    try {
      await logout();
      history.push("/login");
    } catch {
      alert("Failed to log out");
    }
  }

  return (
    <div className="relative bg-white dark:bg-slate-800 overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white dark:bg-slate-800 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white dark:text-slate-800 transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav
                className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                aria-label="Global"
              >
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a href="/">
                      <span className="sr-only">Classnotes Buddy</span>
                      <img
                        alt="Classnotes Buddy"
                        className="h-8 w-auto sm:h-10"
                        src={ClassnotesBuddyLogo}
                      />
                    </a>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className="bg-white dark:bg-slate-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="relative hidden md:block md:ml-10 md:pr-4 md:space-x-8 w-full">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="font-medium text-gray-500 dark:text-white hover:text-gray-900"
                    >
                      {item.name}
                    </a>
                  ))}
                  {!currentUser ? (
                    <a
                      href="/login"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Log in
                    </a>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="w-full h-full md:w-52 lg:w-56 md:block hidden">
                  <div className="ml-4 my-0 flex items-center md:ml-6">
                    <DarkModeButton
                      classes={`ml-auto dark:bg-slate-800 flex-shrink-0 p-1 rounded-full text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-indigo-500`}
                    />
                    {currentUser && (
                      <>
                        <NotificationPopup />
                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-3 relative">
                          <div>
                            <Menu.Button className="max-w-xs rounded-full flex items-center border-none outline-none text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white">
                              <span className="sr-only">Open user menu</span>
                              <img
                                className="h-8 w-8 rounded-full"
                                src={currentUser.photoURL}
                                alt=""
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-slate-800 ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <>
                                      {!item.logout ? (
                                        <a
                                          href={item.href}
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 dark:bg-gray-900"
                                              : "",
                                            "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300",
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      ) : (
                                        <a
                                          href={"#"}
                                          onClick={() => handleLogout()}
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 dark:bg-gray-900"
                                              : "",
                                            "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300",
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      )}
                                    </>
                                  )}
                                </Menu.Item>
                              ))}
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </>
                    )}
                  </div>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-white dark:bg-slate-800 ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src={ClassnotesBuddyLogo}
                        alt=""
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white dark:bg-slate-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 dark:text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close main menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-300 dark:hover:text-gray-500 dark:hover:bg-slate-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  {currentUser ? (
                    // Make the account info align to the right
                    <div className="space-y-6 py-6 border-t">
                      <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={currentUser.photoURL}
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium leading-none dark:text-white">
                            {currentUser.displayName}
                          </div>
                          <div className="text-sm font-medium leading-none text-gray-400 dark:text-gray-100">
                            {currentUser.email}
                          </div>
                        </div>
                        <div class="w-50 ml-auto flex flex-row">
                          <DarkModeButton
                            classes={`ml-auto dark:bg-slate-800 flex-shrink-0 p-1 rounded-full text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-indigo-500`}
                          />
                          <NotificationPopup></NotificationPopup>
                        </div>
                      </div>
                      <div className="mt-3 px-2 space-y-1">
                        {userNavigation.map((item) => (
                          <>
                            {!item.logout ? (
                              <Link
                                key={item.name}
                                to={item.to}
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-500 hover:bg-gray-50 dark:hover:bg-slate-900"
                              >
                                {item.name}
                              </Link>
                            ) : (
                              <Link
                                key={item.name}
                                to={"#"}
                                onClick={() => handleLogout()}
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-gray-500 hover:bg-gray-50 dark:hover:bg-slate-900"
                              >
                                {item.name}
                              </Link>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <a
                      href="/login"
                      className="block w-full px-5 py-3 text-center font-medium text-indigo-600 bg-gray-50 hover:bg-gray-100"
                    >
                      Log in
                    </a>
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline dark:text-white">
                  Simplify the way you
                </span>{" "}
                <span className="block text-indigo-600 dark:text-indigo-400 xl:inline">
                  share notes
                </span>
              </h1>
              <p className="mt-3 text-base text-gray-500 dark:text-gray-300 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                If you are ready to change the way you share notes, then you'll
                want to use our tool to make it fun and easy!
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  {currentUser ? (
                    <Link
                      to="/dashboard"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <Link
                      to="/login"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                    >
                      Get started
                    </Link>
                  )}
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="https://clipchamp.com/watch/3hQJ8n7mb9C"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                  >
                    How to video
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://images.unsplash.com/photo-1517971071642-34a2d3ecc9cd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
