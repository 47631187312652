import React from "react";
import { Link } from "react-router-dom";
import { FolderIcon } from "@heroicons/react/20/solid";

export default function Folder({ folder, viewMode, currentFolder, uid }) {
  var pathname = `/folder/${folder.id}`;

  if (uid) {
    pathname = `/user/${uid}/${folder.id}`;
  }

  return (
    <>
      {viewMode === "list" ? (
        <>
          <td className="rounded-l md:p-1 p-2">
            <Link
              className="text-base leading-4 font-medium text-gray-700 dark:text-white flex flex-row items-center"
              to={{
                pathname: pathname,
                state: { folder: folder },
              }}
            >
              <FolderIcon className="h-5 w-5 mr-2 min-w-max	dark:text-white"></FolderIcon>
              <div className="text-ellipsis overflow-hidden h-full dark:text-white">
                {folder.name}
              </div>
            </Link>
          </td>

          <td className="md:p-1 p-2 dark:text-white">Folder File</td>
        </>
      ) : (
        <Link
          className="bg-white dark:bg-slate-800 py-2 px-3 border border-gray-300 dark:border-slate-500 rounded-md shadow-sm text-base leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex flex-row items-center justify-center  w-full h-full"
          to={{
            pathname: pathname,
            state: { folder: folder },
          }}
        >
          <FolderIcon className="h-5 w-5 mr-2 min-w-max	dark:text-white"></FolderIcon>

          <div className="text-ellipsis overflow-hidden h-full sm:w-screen dark:text-white">
            {folder.name}
          </div>
        </Link>
      )}
    </>
  );
}
