import React from "react";
import Signup from "./authentication/Signup";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Profile from "./authentication/Profile";
import Login from "./authentication/Login";
import PrivateRoute from "./authentication/PrivateRoute";
import ForgotPassword from "./authentication/ForgotPassword";
import About from "./authentication/About";
import Features from "./authentication/Features";
import Landing from "./authentication/Landing";
import PageNotFound from "./authentication/PageNotFound";
import Dashboard from "./core/Dashboard";
import DashboardUsers from "./core/DashboardUsers";
import Spotlight from "./core/Spotlight";
import Notifications from "./core/Notifications";
import Search from "./core/Search";
import Users from "./core/Users";
import Share from "./core/Share";
import Redirect from "./core/Redirect";

function App() {
  if (
    localStorage.theme === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }

  // React.useEffect(() => {
  // 	document.onkeypress = function(event) {
  // 		event = (event || window.event);
  // 		return keyFunction(event);
  // 	}
  // 	document.onmousedown = function(event) {
  // 		event = (event || window.event);
  // 		return keyFunction(event);
  // 	}
  // 	document.onkeydown = function(event) {
  // 		event = (event || window.event);
  // 		return keyFunction(event);
  // 	}

  // 	//Disable right click script
  // 	document.oncontextmenu = function() {
  // 		return false;
  // 	}

  // 	function keyFunction(event) {
  // 		//"F12" key
  // 		if (event.keyCode == 123) {
  // 			return false;
  // 		}

  // 		if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
  // 			return false;
  // 		}
  // 		//"J" key
  // 		if (event.ctrlKey && event.shiftKey && event.keyCode == 74) {
  // 			return false;
  // 		}
  // 		//"S" key
  // 		if (event.keyCode == 83) {
  // 			return false;
  // 		}
  // 		//"U" key
  // 		if (event.ctrlKey && event.keyCode == 85) {
  // 			return false;
  // 		}
  // 		//F5
  // 		if (event.keyCode == 116) {
  // 			return false;
  // 		}
  // 	}
  // }, []);

  return (
    <div className="bg-white dark:bg-slate-800 h-screen">
      <Router>
        <AuthProvider>
          <Switch>
            {/* Drive */}
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute
              exact
              path="/folder/:folderId"
              component={Dashboard}
            />

            <PrivateRoute exact path="/user/:uid" component={DashboardUsers} />
            <PrivateRoute
              exact
              path="/user/:uid/:folderId"
              component={DashboardUsers}
            />

            {/* Notifications */}
            <PrivateRoute path="/notifications" component={Notifications} />

            {/* Profile */}
            <PrivateRoute path="/profile" component={Profile} />

            {/* Spotlight */}
            <PrivateRoute path="/spotlight" component={Spotlight} />

            {/* Search */}
            <PrivateRoute path="/search" component={Search} />

            {/* Redirect */}
            <Route path="/redirect" component={Redirect} />

            {/* Users */}
            <PrivateRoute path="/users" component={Users} />

            {/* Share */}

            <PrivateRoute path="/share" component={Share} />

            {/* Auth */}

            <Route exact path="/" component={Landing} />
            <Route path="/about" component={About} />
            <Route path="/features" component={Features} />
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/forgot-password" component={ForgotPassword} />

            {/* 404 page route */}
            <Route component={PageNotFound} path="*" />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
