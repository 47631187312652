import React, { useState } from "react";
import ReactDOM from "react-dom";
import { DocumentPlusIcon } from "@heroicons/react/20/solid";
import { useAuth } from "../../contexts/AuthContext";
import { storage, database } from "../../firebase";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { v4 as uuidV4 } from "uuid";

export default function AddFileButton({ currentFolder, uFile }) {
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const { currentUser } = useAuth();

  function handleUpload(e) {
    var file = "";
    if (uFile !== undefined || null) {
      file = uFile;
    } else {
      file = e.target.files[0];
    }
    if (currentFolder == null || file == null) return;

    const id = uuidV4();
    setUploadingFiles((prevUploadingFiles) => [
      ...prevUploadingFiles,
      { id: id, name: file.name, progress: 0, error: false },
    ]);
    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder.path.join("/")}/${file.name}`
        : `${currentFolder.path.join("/")}/${currentFolder.name}/${file.name}`;

    const uploadTask = storage
      .ref(`/files/${currentUser.uid}/${filePath}`)
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = snapshot.bytesTransferred / snapshot.totalBytes;
        setUploadingFiles((prevUploadingFiles) => {
          return prevUploadingFiles.map((uploadFile) => {
            if (uploadFile.id === id) {
              return { ...uploadFile, progress: progress };
            }

            return uploadFile;
          });
        });
      },
      () => {
        setUploadingFiles((prevUploadingFiles) => {
          return prevUploadingFiles.map((uploadFile) => {
            if (uploadFile.id === id) {
              return { ...uploadFile, error: true };
            }
            return uploadFile;
          });
        });
      },
      () => {
        setUploadingFiles((prevUploadingFiles) => {
          return prevUploadingFiles.filter((uploadFile) => {
            return uploadFile.id !== id;
          });
        });

        uploadTask.snapshot.ref.getDownloadURL().then((url) => {
          database.files
            .where("name", "==", file.name)
            .where("userId", "==", currentUser.uid)
            .where("folderId", "==", currentFolder.id)
            .get()
            .then((existingFiles) => {
              const existingFile = existingFiles.docs[0];
              if (existingFile) {
                existingFile.ref.update({ url: url });
              } else {
                database.files.add({
                  url: url,
                  name: file.name,
                  approved: false,
                  createdAt: database.getCurrentTimestamp(),
                  folderId: currentFolder.id,
                  userId: currentUser.uid,
                });
              }
            });
        });
      },
    );
  }

  if (uFile !== undefined || null) {
    handleUpload(undefined);
  }

  return (
    <>
      <label
        type="submit"
        className="py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
      >
        {" "}
        <DocumentPlusIcon className="h-5 w-5 group-hover:animate-spin" />
        <input
          type="file"
          onChange={handleUpload}
          style={{
            opacity: 0,
            position: "absolute",
            left: "-9999px",
          }}
        />
      </label>
      {uploadingFiles.length > 0 &&
        ReactDOM.createPortal(
          <div className="rounded-lg shadow-xl border px-2 py-1 w-[180px] absolute bottom-0 right-0 m-2 max-w-xs dark:bg-slate-800">
            {uploadingFiles.map((file) => (
              <div
                key={file.id}
                onClose={() => {
                  setUploadingFiles((prevUploadingFiles) => {
                    return prevUploadingFiles.filter((uploadFile) => {
                      return uploadFile.id !== file.id;
                    });
                  });
                }}
              >
                <div
                  closeButton={file.error}
                  className="text-truncate w-full block dark:text-white"
                >
                  {file.name}
                </div>
                <div>
                  <div className="w-full rounded-full bg-gray-200 h-6 dark:bg-gray-700 mt-2">
                    <div
                      className="rounded-full bg-blue-600 h-6 p-0.5 text-center text-sm flex items-center justify-center font-medium leading-none text-blue-100"
                      style={{
                        width: `${
                          file.error ? 100 : file.progress * 100 - 100
                        }%`,
                      }}
                    >
                      {file.error
                        ? "Error"
                        : `${Math.round(file.progress * 100)}%`}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>,
          document.body,
        )}
    </>
  );
}
