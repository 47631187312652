/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { useAuth } from "../../../../contexts/AuthContext";

import { Link, useHistory } from "react-router-dom";

export default function CallToAction() {
  const { currentUser, logout } = useAuth();
  const history = useHistory();

  return (
    <div className="bg-gray-50 dark:bg-slate-900">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block dark:text-white">
            Built by Tech With Anirudh
          </span>
          <span className="block text-indigo-600 dark:text-indigo-400">
            Anirudh Sriram
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            {!currentUser ? (
              <Link
                to="/login"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Login / Signup
              </Link>
            ) : (
              <Link
                to="/dashboard"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Dashboard
              </Link>
            )}
          </div>
          <div className="ml-3 inline-flex rounded-md shadow">
            <a
              href="http://www.techwithanirudh.tech/"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
            >
              Learn more about Anirudh Sriram
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
