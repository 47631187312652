import React, { useState, useRef } from "react";
import { DocumentIcon } from "@heroicons/react/20/solid";

import { storage, database } from "../../firebase";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { useAuth } from "../../contexts/AuthContext";

export default function File({ file, viewMode, currentFolder }) {
  const { currentUser } = useAuth();

  const [fileSize, setFileSize] = useState(`${0} Bytes`);
  const [fileModified, setFileModified] = useState(`0000-00-00 00:00:00`);

  var fileType = file.name.split(".")[1];
  var pathname = file.url;

  if (fileType) {
    fileType = fileType.toUpperCase() + " ";
  } else {
    fileType = "";
  }

  function getFileSize(fileSize) {
    if (fileSize > 1024) {
      if (fileSize > 1024 * 1024) {
        if (fileSize > 1024 * 1024 * 1024) {
          return Math.round(fileSize / (1024 * 1024 * 1024)) + " GB";
        } else {
          return Math.round(fileSize / (1024 * 1024)) + " MB";
        }
      } else {
        return Math.round(fileSize / 1024) + " KB";
      }
    } else {
      return fileSize + " Bytes";
    }
  }

  // Get the file in the firestore database
  const filePath =
    currentFolder === ROOT_FOLDER
      ? `${currentFolder.path.join("/")}/${file.name}`
      : `${currentFolder.path.join("/")}/${currentFolder.name}/${file.name}`;

  if (viewMode === "list") {
    const fileStorage = storage.ref(`/files/${file.userId}/${filePath}`);

    fileStorage
      .getMetadata()
      .then(function (metadata) {
        // Convert the file size to KB, MB and GB
        const size = metadata.size;
        const modified = metadata.updated;

        setFileModified(modified);
        setFileSize(getFileSize(size));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      {viewMode === "list" ? (
        <>
          <td className="rounded-l md:p-1 p-2">
            <a
              className="text-base leading-4 font-medium text-gray-700 dark:text-white flex flex-row items-center"
              href={pathname}
              target="_blank"
            >
              <DocumentIcon className="h-5 w-5 mr-2 min-w-max	dark:text-white"></DocumentIcon>
              <div className="text-ellipsis overflow-hidden h-full dark:text-white">
                {file.name}
              </div>
            </a>
          </td>

          <td className="md:p-1 p-2 dark:text-white">{fileModified}</td>

          <td className="md:p-1 p-2 dark:text-white"> {fileType}File</td>

          <td className="md:p-1 p-2 rounded-r dark:text-white">{fileSize}</td>
        </>
      ) : (
        <>
          <a
            className="bg-white dark:bg-slate-800 py-2 px-3 border border-gray-300 dark:border-slate-500 rounded-md shadow-sm text-base leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex flex-row items-center justify-center w-full h-full"
            href={pathname}
            target="_blank"
          >
            <DocumentIcon className="h-5 w-5 mr-2 min-w-max dark:text-white"></DocumentIcon>

            <div className="text-ellipsis overflow-hidden h-full sm:w-screen dark:text-white">
              {file.name}
            </div>
          </a>
        </>
      )}
    </>
  );
}
