import React, { Component } from "react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Pagination,
  Configure,
  Panel,
} from "react-instantsearch-dom";
import "./Search.css";
import Stats from "./Stats.js";
import Content from "./Content";
import Facet from "./Facet";
import Navbar from "../Navbar";
import algoliaCredentials from "../../../algoliaCredentials";

const searchClient = algoliasearch(
  algoliaCredentials[0],
  algoliaCredentials[1],
);

class Search extends Component {
  render() {
    return (
      <>
        <Navbar />
        <div className="mt-4"></div>
        <InstantSearch
          searchClient={searchClient}
          indexName="prod_classnotesbuddy"
        >
          <main className="search-container">
            <Configure
              hitsPerPage={3}
              attributesToSnippet={["content:14"]}
              snippetEllipsisText={" [...]"}
            />
            <div className="right-panel">
              <div id="hits">
                {/* Uncomment the following widget to add hits list */}
                <Content />
              </div>
              <div id="searchbox">
                {/* Uncomment the following widget to add a search bar */}
                {
                  <SearchBox
                    translations={{
                      placeholder: "Search notes",
                    }}
                  />
                }
              </div>
              <div id="stats">
                {/* Uncomment the following widget to add search stats */}
                {<Stats />}
              </div>
              <div id="pagination">
                {/* Uncomment the following widget to add pagination */}
                {<Pagination />}
              </div>
            </div>
          </main>
        </InstantSearch>
      </>
    );
  }
}

export default Search;
