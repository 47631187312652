import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import firebase from "firebase/app";
import { API_URL } from "../constants";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  var googleProvider = new firebase.auth.GoogleAuthProvider();

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function googleLogin() {
    return auth.signInWithPopup(googleProvider);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateDisplayName(displayName) {
    return currentUser.updateProfile({ displayName: displayName });
  }

  async function updateProfilePicture(files) {
    const data = new FormData();
    data.append("uid", currentUser.uid);
    data.append("avatar", files[0]);

    const response = await fetch(`${API_URL}api/v1/profile`, {
      method: "POST",
      body: data,
    });
    const photoURL = await response.text();

    return currentUser.updateProfile({ photoURL: photoURL });
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    googleLogin,
    signup,
    logout,
    resetPassword,
    updateDisplayName,
    updateProfilePicture,
    updateEmail,
    updatePassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
