import React from "react";
import { database } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import ClassnotesBuddyLogo from "../../logos/classnotesbuddy-logo.svg";

export default function AddFolderButton({ currentFolder }) {
  const [open, setOpen] = useState(false);

  const openInstallButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  async function install() {
    // Hide the app provided install promotion
    closeModal();
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    const { outcome } = await deferredPrompt.userChoice;
    // Optionally, send analytics event with outcome of user choice
    console.log(`User response to the install prompt: ${outcome}`);
    // We've used the prompt, and can't use it again, throw it away
    setDeferredPrompt(null);
  }

  window.addEventListener("beforeinstallprompt", (e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();
    // Stash the event so it can be triggered later.
    setDeferredPrompt(e);
    // Update UI notify the user they can install the PWA
    openModal();
    // Optionally, send analytics event that PWA install promo was shown.
    console.log(`'beforeinstallprompt' event was fired.`);
  });

  window.addEventListener("appinstalled", () => {
    // Hide the app-provided install promotion
    closeModal();
    // Clear the deferredPrompt so it can be garbage collected
    setDeferredPrompt(null);
    // Optionally, send analytics event to indicate successful install
    console.log("PWA was installed");
  });

  // Display mode
  function getPWADisplayMode() {
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)",
    ).matches;
    if (document.referrer.startsWith("android-app://")) {
      return "twa";
    } else if (navigator.standalone || isStandalone) {
      return "standalone";
    }
    return "browser";
  }

  useEffect(() => {
    function hideOrShowInstallBtn(displayMode) {
      if (displayMode === "standalone" || displayMode === "twa") {
        openInstallButtonRef.current.classList.toggle("hidden", true);
      }
    }

    hideOrShowInstallBtn(getPWADisplayMode());

    window
      .matchMedia("(display-mode: standalone)")
      .addEventListener("change", (evt) => {
        let displayMode = "browser";
        if (evt.matches) {
          displayMode = "standalone";
        }
        // Log display mode change to analytics
        console.log("DISPLAY_MODE_CHANGED", displayMode);
        hideOrShowInstallBtn(displayMode);
      });
  }, []);

  return (
    <>
      <button
        onClick={openModal}
        type="button"
        ref={openInstallButtonRef}
        className="py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
      >
        {" "}
        <ArrowDownTrayIcon className="h-5 w-5 group-hover:animate-spin" />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white dark:bg-slate-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full">
                  <div className="bg-white dark:bg-slate-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-40 w-40 bg-indigo-100 sm:mx-0 rounded-xl p-2 sm:mx-0">
                        <img
                          src={ClassnotesBuddyLogo}
                          className="h-40 w-40 rounded-xl"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-3xl leading-6 font-medium text-gray-900 dark:text-gray-100"
                        >
                          Classnotes Buddy
                        </Dialog.Title>
                        <div className="mt-2 hidden sm:block">
                          <p className="text-sm text-gray-500 dark:text-gray-300">
                            This app can be installed on your PC or mobile
                            device. This will allow this web app to look and
                            behave like any other installed app. You will find
                            it in your app lists and be able to pin it to your
                            home screen, start menus or task bars. This
                            installed web app will also be able to safely
                            interact with other apps and your operating system.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col items-start mt-4">
                      <h1 className="text-2xl dark:text-white">Description</h1>
                      <p className="dark:text-white">
                        A simple way to share notes with your classmates. In the
                        event that a student misses a class or loses their
                        classwork, this app can be useful.
                      </p>
                    </div>
                  </div>
                  <div className="bg-gray-50 dark:bg-gray-900 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      onClick={install}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Install Classnotes Buddy
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={closeModal}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
