import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { storage, database } from "../../firebase";
import { ROOT_FOLDER } from "../../hooks/useFolder";
import { useAuth } from "../../contexts/AuthContext";

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default function User({ user, viewMode }) {
  const { currentUser } = useAuth();

  const [filesUploaded, setFilesUploaded] = useState(`${0} Files`);
  const [files, setFiles] = useState([]);
  const [lastUploaded, setLastUploaded] = useState(`0000-00-00 00:00:00`);

  var userFilesList = [];

  useEffect(() => {
    // List all the files in the database
    const unsubscribe = database.files
      .orderBy("createdAt")
      .onSnapshot((snapshot) => {
        const documents = snapshot.docs.map(database.formatDoc);
        setFiles(documents);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      files.map((file) => {
        // Get the user's file count in userFilesList
        if (file.userId === user.uid) {
          userFilesList.push(file);
        }
      });

      setFilesUploaded(userFilesList.length);
    }
    fetchData();
  }, [files]);

  if (!user.displayName) {
    var tempDisplayName = user.email.substring(0, user.email.lastIndexOf("@"));
    if (tempDisplayName.includes(".")) {
      var tempDisplayName = tempDisplayName.split(".");
      var tempDisplayName = tempDisplayName.join(" ");
    }
    var tempDisplayName = toTitleCase(tempDisplayName);

    user.displayName = tempDisplayName;
  }

  if (!user.photoURL)
    user.photoURL =
      "https://cdn.iconscout.com/icon/free/png-64/user-824-133499.png";

  return (
    <>
      {viewMode === "list" ? (
        <>
          <td className="rounded-l md:p-1 p-2">
            <Link
              className="text-base leading-4 font-medium text-gray-700 dark:text-white flex flex-row items-center"
              to={{
                pathname: `/user/${user.uid}`,
              }}
            >
              <img
                className="h-8 w-8 rounded-full mr-2 min-w-max	dark:text-white"
                src={user.photoURL}
              ></img>
              <div className="text-ellipsis overflow-hidden h-full dark:text-white">
                {user.displayName}
              </div>
            </Link>
          </td>

          <td className="md:p-1 p-2 dark:text-white">{filesUploaded}</td>

          <td className="md:p-1 p-2 dark:text-white rounded-r">
            {lastUploaded}
          </td>
        </>
      ) : (
        <Link
          className="bg-white dark:bg-slate-800 py-2 px-3 border border-gray-300 dark:border-slate-500 rounded-md shadow-sm text-base leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex flex-row items-center justify-center  w-full h-full"
          to={{
            pathname: `/user/${user.uid}`,
          }}
        >
          <img
            className="h-8 w-8 rounded-full mr-2 min-w-max	inline"
            src={user.photoURL}
          ></img>
          <div className="text-ellipsis overflow-hidden h-full sm:w-screen dark:text-white inline inline-flex items-center">
            {user.displayName}
          </div>
        </Link>
      )}
    </>
  );
}
