import React from "react";
import { Link } from "react-router-dom";
import { ROOT_FOLDER } from "../../hooks/useFolder";

export default function FolderBreadcrumbs({ currentFolder, uid }) {
  let basepath = "/dashboard";
  let path = currentFolder === ROOT_FOLDER ? [] : [ROOT_FOLDER];
  if (currentFolder) path = [...path, ...currentFolder.path];

  if (uid) {
    basepath = `/user/${uid}`;
  }

  return (
    <li
      className="flex flex-row space-x-2"
      listProps={{ className: "bg-white pl-0 m-0" }}
    >
      {path.map((folder, index) => (
        <Link
          key={folder.id}
          to={{
            pathname: folder.id ? `/folder/${folder.id}` : basepath,
            state: {
              folder: { ...folder, path: path.slice(1, index) },
            },
          }}
          className="text-ellipsis overflow-hidden inline-block text-indigo-500 dark:text-indigo-300 hover:text-indigo-700 dark:hover:text-indigo-500"
          style={{ maxWidth: "150px" }}
        >
          {folder.name}
        </Link>
      ))}
      {currentFolder && (
        <div
          className="text-ellipsis overflow-hidden inline-block dark:text-white"
          style={{ maxWidth: "200px" }}
        >
          {currentFolder.name}
        </div>
      )}
    </li>
  );
}
