import React from "react";
import Hero from "./sections/Hero";
import Footer from "./sections/Footer";
import CallToAction from "./sections/CallToAction";
import Author from "./sections/Author";
import Attribution from "./sections/Attribution";

export default function Landing() {
  return (
    <>
      <div
        className={
          "relative bg-white dark:bg-slate-800 h-screen overflow-y-scroll"
        }
      >
        <Hero />
        <CallToAction />
        <Attribution />
        <Author />
        <Footer />
      </div>
    </>
  );
}
