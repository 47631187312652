import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

console.log(process.env.REACT_APP_FIREBASE_API_KEY);
const app = firebase.initializeApp({
  apiKey: "AIzaSyC58WsaLEsKbqBeYEZ-KVbqvLW-_DEHbIQ",
  authDomain: "classnotes-buddy.firebaseapp.com",
  databaseUrl: "https://classnotes-buddy.asia-southeast2.firebasedatabase.app",
  projectId: "classnotes-buddy",
  storageBucket: "classnotes-buddy.appspot.com",
  messagingSenderId: "504092177711",
  appId: "1:504092177711:web:4b73073f5dc2ac15929dda",
  measurementId: "G-4LM2P8WTQG",
});

const firestore = app.firestore();
export const database = {
  folders: firestore.collection("folders"),
  files: firestore.collection("files"),
  formatDoc: (doc) => {
    return { id: doc.id, ...doc.data() };
  },
  getCurrentTimestamp: firebase.firestore.FieldValue.serverTimestamp,
};

export const storage = app.storage();
export const auth = app.auth();
export default app;
