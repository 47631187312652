import React, { useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../core/Navbar";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

export default function Profile() {
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const profileImage = useRef(null);
  const profileImageUpload = useRef(null);
  const {
    currentUser,
    updatePassword,
    updateEmail,
    updateDisplayName,
    updateProfilePicture,
    logout,
  } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleLogout() {
    setError("");

    try {
      await logout();
      history.push("/login");
    } catch {
      setError("Failed to log out");
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    // If password does not exist, set value to empty string
    if (!passwordRef.current) {
      passwordRef.current = {
        value: "",
      };
    }
    if (!passwordConfirmRef.current) {
      passwordConfirmRef.current = {
        value: "",
      };
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    }

    const promises = [];
    setLoading(true);
    setError("");

    if (usernameRef.current.value !== currentUser.displayName) {
      promises.push(updateDisplayName(usernameRef.current.value));
    }
    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value));
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value));
    }

    const files = profileImageUpload.current.files;
    if (files.length != 0) {
      promises.push(updateProfilePicture(files));
    }

    Promise.all(promises)
      .then(() => {
        history.push("/profile");
      })
      .catch(() => {
        setError("Failed to update account");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function handleUpload(e) {
    const file = e.target.files[0];
    // convert to b64 and set preview image
    profileImage.current.src = await toBase64(file);
  }
  return (
    <>
      <Navbar />
      <div className="pt-5 md:mt-0 md:col-span-2">
        <form
          method="POST"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <div className="sm:rounded-md sm:overflow-hidden">
            <div className="px-4 py-5 bg-white dark:bg-slate-800 space-y-6 sm:p-6">
              <div>
                <p className="text-xl text-gray-900 dark:text-gray-300">
                  Profile
                </p>
                <h1 className="text-3xl font-bold dark:text-white">
                  Update Information
                </h1>
              </div>
              {error && (
                <div className="rounded-xl shadow-sm text-white bg-red-500 px-2 py-3">
                  {error}
                </div>
              )}
              <div className="flex align-start items-center flex-col sm:flex-row">
                <img
                  className="rounded-full w-[125px] h-[125px]"
                  src={currentUser.photoURL}
                  title={currentUser.photoURL}
                  alt={currentUser.photoURL}
                />
                <div className="p-5">
                  <h2 className="text-4xl sm:text-left text-center dark:text-white">
                    {currentUser.displayName}
                  </h2>
                  <p className="text-gray-600 sm:text-left text-center dark:text-white">
                    {currentUser.email}
                  </p>
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Username*
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  required
                  autoComplete="username"
                  ref={usernameRef}
                  defaultValue={currentUser.displayName}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-slate-800 dark:focus:border-indigo-300 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                />
              </div>

              <div className="col-span-6 sm:col-span-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Email address*
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  required
                  autoComplete="email"
                  ref={emailRef}
                  defaultValue={currentUser.email}
                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-slate-800 dark:focus:border-indigo-300 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                />
              </div>

              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-100 dark:bg-indigo-500 dark:hover:bg-indigo-700 dark:text-indigo-200 px-4 py-2 text-left text-sm font-medium text-indigo-900 hover:bg-indigo-200 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
                      <span>Want to change your password?</span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-indigo-500 dark:text-indigo-100`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pt-4 pb-2">
                      <div className="flex flex-col space-y-4">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            Password*
                          </label>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            placeholder="Leave blank to keep the same"
                            autoComplete="password"
                            ref={passwordRef}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-slate-800 dark:focus:border-indigo-300 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-4">
                          <label
                            htmlFor="password-confirm"
                            className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            Password confirmation*
                          </label>
                          <input
                            type="password"
                            name="password-confirm"
                            id="password-confirm"
                            placeholder="Leave blank to keep the same"
                            autoComplete="new-password"
                            ref={passwordConfirmRef}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md dark:bg-slate-800 dark:focus:border-indigo-300 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          />
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>

              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Profile Picture
                </label>
                <div className="mt-1 flex items-center">
                  <a
                    className="inline-block h-12 w-12 rounded overflow-hidden bg-gray-100"
                    href={currentUser.photoURL}
                  >
                    <img
                      src={currentUser.photoURL}
                      title={currentUser.photoURL}
                      alt={currentUser.photoURL}
                      ref={profileImage}
                      id="profilePicPreview"
                      className="w-12 h-12"
                    />
                  </a>
                  <label
                    htmlFor="profilePic"
                    className="relative cursor-pointer ml-3 bg-white dark:bg-slate-800 py-2 px-3 border border-gray-300 rounded-md dark:bg-slate-800 dark:focus:border-indigo-300 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white shadow-sm text-sm leading-4 font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span>Change</span>
                    <input
                      type="file"
                      id="profilePic"
                      name="profilePic"
                      accept="image/*"
                      onChange={handleUpload}
                      ref={profileImageUpload}
                      className="sr-only"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="px-4 py-3 bg-gray-50 dark:bg-slate-900 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
