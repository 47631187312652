import React, { useRef, useState, useEffect } from "react";
import { SunIcon, MoonIcon } from "@heroicons/react/20/solid";

function DarkModeToggle({ currentFolder, classes }) {
  const darkModeButtonRef = useRef(null);
  const [mode, setMode] = useState(
    document.documentElement.classList.contains("dark") ? "dark" : "light",
  );

  useEffect(() => {
    localStorage.setItem("theme", mode);
    if (mode === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [mode]);

  function toggleMode() {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  }

  return (
    <>
      <button
        onClick={toggleMode}
        type="button"
        ref={darkModeButtonRef}
        className={
          classes
            ? classes
            : "py-2 px-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
        }
        aria-label="Toggle dark mode"
      >
        {" "}
        {mode === "light" ? (
          <MoonIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <SunIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </button>
    </>
  );
}

export default DarkModeToggle;
