import React from "react";
import Image from "../../illustrations/404-error.png";

const PageNotFound = () => {
  return (
    <div className="w-screen h-screen flex items-center justify-center px-4">
      <section className="py-8 px-4 text-center">
        <div className="max-w-auto mx-auto">
          <div className="md:max-w-lg mx-auto">
            <img className="w-full mx-auto" src={Image} />
          </div>
          <h2 className="mt-8 uppercase text-xl lg:text-5xl font-black dark:text-white">
            We are sorry, Page not found!
          </h2>
          <p className="mt-6 uppercase text-sm lg:text-base text-gray-900 dark:text-gray-100">
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <a
            href="/"
            className="mt-6 bg-indigo-500 hover:bg-indigo-700 text-white font-light py-4 px-6 rounded-full inline-block uppercase shadow-md"
          >
            Back To Homepage
          </a>
          <p className="mt-6 text-xs text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-500">
            <a
              href="https://iconscout.com/illustrations/404-error"
              target="_blank"
              className="underline"
            >
              404 error Illustration
            </a>{" "}
            by{" "}
            <a
              href="https://iconscout.com/contributors/iconscout"
              target="_blank"
              className="underline"
            >
              IconScout Store
            </a>{" "}
            on{" "}
            <a
              href="https://iconscout.com"
              target="_blank"
              className="underline"
            >
              IconScout
            </a>{" "}
          </p>
        </div>
      </section>
    </div>
  );
};

export default PageNotFound;
