import { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./ContextMenu.css";

const ContextMenu = ({ targetId, options, classes, callback }) => {
  const [contextData, setContextData] = useState({
    visible: false,
    posX: 0,
    posY: 0,
  });
  const contextRef = useRef(null);

  useEffect(() => {
    const contextMenuEventHandler = (event) => {
      const targetElement = document.getElementById(targetId);
      if (targetElement && targetElement.contains(event.target)) {
        event.preventDefault();
        setContextData({
          visible: true,
          posX: event.clientX,
          posY: event.clientY,
        });
        const contextMenuItems = document.getElementById("contextMenuItems");
        callback(event, contextMenuItems);
      } else if (
        contextRef.current &&
        !contextRef.current.contains(event.target)
      ) {
        setContextData({ ...contextData, visible: false });
      }
    };

    const offClickHandler = (event) => {
      if (contextRef.current && !contextRef.current.contains(event.target)) {
        setContextData({ ...contextData, visible: false });
      }
    };

    document.addEventListener("contextmenu", contextMenuEventHandler);
    document.addEventListener("click", offClickHandler);
    return () => {
      document.removeEventListener("contextmenu", contextMenuEventHandler);
      document.removeEventListener("click", offClickHandler);
    };
  }, [contextData, targetId]);

  useLayoutEffect(() => {
    if (
      contextData.posX + contextRef.current?.offsetWidth >
      window.innerWidth
    ) {
      setContextData({
        ...contextData,
        posX: contextData.posX - contextRef.current?.offsetWidth,
      });
    }
    if (
      contextData.posY + contextRef.current?.offsetHeight >
      window.innerHeight
    ) {
      setContextData({
        ...contextData,
        posY: contextData.posY - contextRef.current?.offsetHeight,
      });
    }
  }, [contextData]);

  return (
    <div
      ref={contextRef}
      className="contextMenu dark:bg-slate-800"
      style={{
        display: `${contextData.visible ? "block" : "none"}`,
        left: contextData.posX,
        top: contextData.posY,
      }}
      id="contextMenu"
    >
      <div
        className={`optionsList ${classes?.listWrapper}`}
        id="contextMenuItems"
      >
        {options.map((option) => (
          <li key={option} className={`optionListItem ${classes?.listItem}`}>
            {option}
          </li>
        ))}
      </div>
    </div>
  );
};

// //
// 				<ContextMenu
// 					targetId='1'
// 					options={['Rename', 'Delete', 'Move']}
// 					classes={{
// 						listWrapper: 'h-32 w-64 rounded-xl bg-white p-1 text-lg shadow-lg',
// 						listItem: 'flex flex-row items-center justify-between rounded-lg px-2 hover:bg-gray-100'
// 					}}
// 				/>
export default ContextMenu;
